import React from 'react';

import Bulb from '../../common/components/icons/bulb-o';
import DonorProfileMedicalHistoryRow from './DonorProfileMedicalHistoryRow';
import DonorProfileMedicalHistoryTable from './DonorProfileMedicalHistoryTable';
import { colors } from '../../app-theme';

const DonorProfileMedicalHistory = ({ issues }) => {
  return (
    <div className="donor-profile__medical-history">
      <h5>Donor and Family Medical History</h5>
      <div className="banner info">
        <Bulb fill={colors.brandBlue} /> Medical overview of Donor and Donor’s family: Parents, Siblings and
        Grandparents
      </div>

      <DonorProfileMedicalHistoryTable defaultExpanded heading="Vision & Hearing">
        <DonorProfileMedicalHistoryRow issue="Deafness before age 50" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Cataracts before age 50" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Blindness" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Color blindness" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Glaucoma" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Nearsighted" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Farsighted" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Contacts or Glasses" issues={issues} />
      </DonorProfileMedicalHistoryTable>

      <DonorProfileMedicalHistoryTable heading="Cardiovascular & Respiratory Health">
        <DonorProfileMedicalHistoryRow issue="Heart Attack" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Heart Defects" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Heart Murmur" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="High Blood Pressure" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Asthma" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Emphysema" issues={issues} />
      </DonorProfileMedicalHistoryTable>

      <DonorProfileMedicalHistoryTable heading="Central Nervous System">
        <DonorProfileMedicalHistoryRow issue="Migraines" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Senility" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Mentally Handicapped" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Tourette’s Syndrome" issues={issues} label="Tourette's Syndrome" />
        <DonorProfileMedicalHistoryRow issue="Epilepsy" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Hydrocephalus" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Huntington’s Chorea" issues={issues} label="Huntington's Chorea"  />
      </DonorProfileMedicalHistoryTable>

      <DonorProfileMedicalHistoryTable heading="Blood & Endocrine">
        <DonorProfileMedicalHistoryRow
          issue="Thalassemia, pulmonary embolism, blood clot, iron deficiency anemia, thrombocytopenia, DVT, anemia (any type), Von Willebrand disease"
          issues={issues}
          label="Thalassemia, pulmonary embolism, blood clot, iron deficiency anemia, thrombocytopenia, DVT, anemia (any type), Von Willebrand disease"
        />
        <DonorProfileMedicalHistoryRow issue="Sickle Cell Anemia" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Hemophilia" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Gaucher’s Disease" issues={issues} label="Gaucher's Disease"/>
        <DonorProfileMedicalHistoryRow issue="Thyroid Disease" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Adrenal Dysfunction" issues={issues} />
      </DonorProfileMedicalHistoryTable>

      <DonorProfileMedicalHistoryTable heading="Digestive/GI">
        <DonorProfileMedicalHistoryRow issue="Crohn’s Disease" issues={issues} label="Crohn's Disease"  />
        <DonorProfileMedicalHistoryRow issue="Liver Disease" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Kidney Disease" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Cystic Fibrosis" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="(Type 1) Diabetes" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Wilson’s Disease" issues={issues} label="Wilson's Disease"/>
        <DonorProfileMedicalHistoryRow issue="Cleft Palette" issues={issues} />
      </DonorProfileMedicalHistoryTable>

      <DonorProfileMedicalHistoryTable heading="Skin & Musculoskeletal">
        <DonorProfileMedicalHistoryRow issue="Skin Disorder" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Skin pigmentation abnormality" issues={issues} />
        <DonorProfileMedicalHistoryRow
          issue="Lou Gehrigs's Disease"
          issues={issues}
          label="Lou Gehrig's Disease"
        />
        <DonorProfileMedicalHistoryRow issue="Cerebral Palsy" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Spina Bifida" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Muscular Dystrophy" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Osteoporosis" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Dwarfism" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Arthritis" issues={issues} />
      </DonorProfileMedicalHistoryTable>

      <DonorProfileMedicalHistoryTable heading="Immune System & Infection">
        <DonorProfileMedicalHistoryRow issue="Immune Deficiency Disorder" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Multiple Sclerosis" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Lupus" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Hepatitis A" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Hepatitis B" issues={issues} />
        {/* @TODO where does this data come from? */}
        <DonorProfileMedicalHistoryRow issue="Other Conditions" issues={issues} />
      </DonorProfileMedicalHistoryTable>

      <DonorProfileMedicalHistoryTable heading="Cancer">
        <DonorProfileMedicalHistoryRow issue="Lung Cancer" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Breast Cancer" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Thyroid Cancer" issues={issues} />
        <DonorProfileMedicalHistoryRow
          issue="GI Cancer  (esophagus, stomach, intestine, colon, pancreatic, liver, gallbladder)"
          issues={issues}
          label={<span>GI Cancer (esophagus, stomach, intestine, colon, more)</span>}
        />
        <DonorProfileMedicalHistoryRow issue="Cervical Cancer" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Skin Cancer" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Ovarian Cancer" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Uterine Cancer" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Leukemia" issues={issues} />
        {/* @TODO where does this data come from? */}
        <DonorProfileMedicalHistoryRow issue="Other Cancer" issues={issues} />
      </DonorProfileMedicalHistoryTable>

      <DonorProfileMedicalHistoryTable heading="Mental Health & Substance Abuse">
        <DonorProfileMedicalHistoryRow
          issue="Bipolar disorder"
          issues={issues}
          label="Bipolar disorder"
        />
        <DonorProfileMedicalHistoryRow
          issue="Schizophrenia"
          issues={issues}
          label="Schizophrenia"
        />
        <DonorProfileMedicalHistoryRow issue="Alcoholism" issues={issues} />
        <DonorProfileMedicalHistoryRow issue="Drug Use" issues={issues} />
      </DonorProfileMedicalHistoryTable>
    </div>
  );
};

export default DonorProfileMedicalHistory;
