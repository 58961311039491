import React from 'react';

import ContactInformation from '../Contact/ContactInformation';
import ContactUsForm from '../Contact/ContactUsForm';
import ScheduleAppointment from '../Contact/ScheduleAppointment';

const DonorProfileContactUs = ({ closeButtonText, donorId, isVisible, onClose }) => {
  return (
    <div className="donor-profile__contact-form">
      <div className="modal__content">
        <h3 className="modal__header">Contact The World Egg & Sperm Bank</h3>
        <div className="modal__horizontal">
          <div>
            <ContactInformation headerText="Contact Information" />
            <hr className="section-divider" />
            <h4 className="margin-bottom__s">Schedule Appointment</h4>
            <ScheduleAppointment donorId={donorId} />
          </div>
          <div className="modal__contact-form">
            <ContactUsForm
              closeButtonText={closeButtonText}
              donorId={donorId}
              isVisible={isVisible}
              onClose={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonorProfileContactUs;
