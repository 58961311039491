// Spatial System
const gridBaseUnit = 8;

export const spacing = {
  u0: gridBaseUnit * 0.5 + 'px',
  u1: gridBaseUnit + 'px',
  u2: gridBaseUnit * 2 + 'px',
  u3: gridBaseUnit * 3 + 'px',
  u4: gridBaseUnit * 4 + 'px',
  u5: gridBaseUnit * 5 + 'px',
  u6: gridBaseUnit * 6 + 'px',
};

export const breakpoints = {
  tabletPortrait: `@media (min-width: 768px)`,
  tabletLandscape: `@media (min-width: 1024px)`,
  desktop: `@media (min-width: 1140px)`,
};

export const colors = {
  // Brand Colors
  brandPurple: '#652365',
  brandBlue: '#095093',
  brandGold: '#D1A053',

  // Supporting Colors
  lightPurple: '#947AB2',
  lightBlue: '#C5C6E0',

  // Utility Colors
  success: '#4CAF50',
  danger: '#F44336',
  warning: '#FF9800',

  // Backgrounds
  background: 'white',

  // Neutrals
  darkGray: '#1E1D1E',
  lightGray: '#BCBCC0',

  // Border Colors

  // FX
};

export const typography = {
  // Typeface
  sans: '"Muli", "Helvetica Neue", sans-serif',
  slab: '"Roboto Slab", "Georgia", "Times New Roman"',

  // Weights
  regular: 400,
  semi: 600,
  bold: 800,

  // Default COlors
  bodyColor: colors.darkGray,

  // Display Text
  displayXL: {
    fontSize: '48px',
    lineHeight: '60px',
    fontFamily: '"Roboto Slab", "Georgia", "Times New Roman"',
    fontWeight: 400,
    color: colors.brandPurple,
  },
  displayL: {
    fontSize: '34px',
    lineHeight: '42px',
    fontFamily: '"Roboto Slab", "Georgia", "Times New Roman"',
    fontWeight: 400,
    color: colors.brandPurple,
  },
  displayM: {
    fontSize: '24px',
    lineHeight: '32px',
    fontFamily: '"Muli", "Helvetica Neue", sans-serif',
    fontWeight: 800,
  },
  displayS: {
    fontSize: '20px',
    lineHeight: '28px',
    fontFamily: '"Muli", "Helvetica Neue", sans-serif',
    fontWeight: 800,
  },

  // Content Text
  body: {
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: '"Muli", "Helvetica Neue", sans-serif',
    fontWeight: 400,
  },
  small: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: '"Muli", "Helvetica Neue", sans-serif',
    fontWeight: 400,
  },

  // Emphasis
  muted: colors.lightGray,
};

export const globalBorder = `1px solid ${colors.lightGray}`;

export const globalBorderRadius = '6px';

export const globalBoxShadow = '0 2px 30px 0 rgba(0,0,0,0.10), 0 2px 28px 0 rgba(0,0,0,0.16)';

export const buttonTypes = {};

export default {
  gridBaseUnit,
  spacing,
  breakpoints,
  colors,
  typography,
  globalBorder,
  globalBorderRadius,
  globalBoxShadow,
  buttonTypes,
};
